// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dec-apis-process-cancel-js": () => import("./../../../src/pages/dec/apis/process/cancel.js" /* webpackChunkName: "component---src-pages-dec-apis-process-cancel-js" */),
  "component---src-pages-dec-apis-process-confirmation-js": () => import("./../../../src/pages/dec/apis/process/confirmation.js" /* webpackChunkName: "component---src-pages-dec-apis-process-confirmation-js" */),
  "component---src-pages-dec-apis-process-create-js": () => import("./../../../src/pages/dec/apis/process/create.js" /* webpackChunkName: "component---src-pages-dec-apis-process-create-js" */),
  "component---src-pages-dec-apis-process-delete-js": () => import("./../../../src/pages/dec/apis/process/delete.js" /* webpackChunkName: "component---src-pages-dec-apis-process-delete-js" */),
  "component---src-pages-dec-apis-process-health-js": () => import("./../../../src/pages/dec/apis/process/health.js" /* webpackChunkName: "component---src-pages-dec-apis-process-health-js" */),
  "component---src-pages-dec-apis-process-mail-js": () => import("./../../../src/pages/dec/apis/process/mail.js" /* webpackChunkName: "component---src-pages-dec-apis-process-mail-js" */),
  "component---src-pages-dec-apis-process-resend-js": () => import("./../../../src/pages/dec/apis/process/resend.js" /* webpackChunkName: "component---src-pages-dec-apis-process-resend-js" */),
  "component---src-pages-dec-apis-process-search-js": () => import("./../../../src/pages/dec/apis/process/search.js" /* webpackChunkName: "component---src-pages-dec-apis-process-search-js" */),
  "component---src-pages-dec-apis-process-sign-js": () => import("./../../../src/pages/dec/apis/process/sign.js" /* webpackChunkName: "component---src-pages-dec-apis-process-sign-js" */),
  "component---src-pages-dec-glosary-js": () => import("./../../../src/pages/dec/glosary.js" /* webpackChunkName: "component---src-pages-dec-glosary-js" */),
  "component---src-pages-dec-introduction-js": () => import("./../../../src/pages/dec/introduction.js" /* webpackChunkName: "component---src-pages-dec-introduction-js" */),
  "component---src-pages-dec-widget-js": () => import("./../../../src/pages/dec/widget.js" /* webpackChunkName: "component---src-pages-dec-widget-js" */),
  "component---src-pages-easysign-apis-process-create-js": () => import("./../../../src/pages/easysign/apis/process/create.js" /* webpackChunkName: "component---src-pages-easysign-apis-process-create-js" */),
  "component---src-pages-easysign-glosary-js": () => import("./../../../src/pages/easysign/glosary.js" /* webpackChunkName: "component---src-pages-easysign-glosary-js" */),
  "component---src-pages-easysign-introduction-js": () => import("./../../../src/pages/easysign/introduction.js" /* webpackChunkName: "component---src-pages-easysign-introduction-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

